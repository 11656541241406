import { CloseOutlined } from '@ant-design/icons';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  PopperProps,
  Slider,
  Stack,
  Typography,
  TypographyPropsVariantOverrides,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { foodApi } from 'api';
import { FoodDetailsResponse, MealResponse, UsdaMeasure, UsdaNutritionResponse } from 'api/generated/MNT';
import { MealItem } from 'apiClients/mpq';
import { FoodSearchItem } from 'client-search/food-search-index';
import { useFeatures } from 'context/FeatureContext';
import { RelevantQueuesTileView, useFoodEditorWhiteboardService } from 'food-editor/components/food-editor';
import { formatNumber } from 'food-editor/utils/utils';
import _, { min } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { usePatientMealHistory } from 'pages/QueueItem/meal-builder/FoodDrawer';
import { getFoodEditorUrl } from 'pages/QueueItem/meal-builder/MealSummary';
import { RecommendedMeals } from 'pages/QueueItem/meal-builder/RecommendedMeals';
import { ExternalFoodSearchResult } from 'pages/QueueItem/meal-builder/useFoodSearch';
import React, { useEffect, useMemo, useRef } from 'react';
import { ReactNode, useState } from 'react';
import { useFoodDetails, UseFoodRes } from 'services/FoodDetailsService';
import { create } from 'zustand';

export type FoodDetailsPopupProps = {
  anchorTo?: 'mouse' | 'element',
  internalFoodName?: string,
  mealItem?: MealItem,
  foodSearchRes?: FoodSearchItem,
  externalFoodRes?: ExternalFoodSearchResult,
  showRecentMealsWithQueueId?: number,
  handleMealClick?: (mealResponse: MealResponse, index: number, source?: string) => void,
};

const useFoodDetailsPopupStore = create<{
  isMouseOverPopup: boolean,
  isMouseOverTarget: HTMLElement | null,
  componentPosition: DOMRect | null,
  mousePosition: { x: number, y: number } | null,
  isPopupShown: boolean,
  props: FoodDetailsPopupProps | null,
}>(() => ({
  isMouseOverPopup: false,
  isMouseOverTarget: null,
  componentPosition: null,
  mousePosition: null,
  isPopupShown: false,
  props: null,
}));

export const FoodDetailsPopupSingletonContainer = () => {
  const state = useFoodDetailsPopupStore();
  const isVisible = (state.isMouseOverPopup || !!state.isMouseOverTarget) && !!state.props;
  if (!isVisible) {
    return null;
  }

  return (
    <div
      style={{ position: 'absolute', zIndex: 999, display: 'flex' }}
      onMouseEnter={() => {
        useFoodDetailsPopupStore.setState({ isMouseOverPopup: true });
      }}
      onMouseLeave={() => {
        useFoodDetailsPopupStore.setState({ isMouseOverPopup: false });
      }}
    >
      <FoodDetailsPopup {...state.props} />
    </div>
  );
};

const FoodDetailsPopup = (props: FoodDetailsPopupProps) => {
  const state = useFoodDetailsPopupStore();
  return (
    <DelayRender delaySeconds={0.5}>
      <FoodDetailsPopupWindow
        foodDetailsPopupProps={props}
        anchorPosition={state.componentPosition}
        mousePosition={state.mousePosition}
      />
    </DelayRender>
  );
};

/*
  Note: the popup will be stuck if the element hovered over is unmounted
  this is normally caused by keyboard events
  e.g. a drawer closed by pressing esc key or search results changed while user is typing

  Easy fix is to use forceClear() along with these events
  For examples, search for forceClear in FoodDrawer.tsx
*/
export const useFoodDetailsPopup = () => {
  const flags = useFeatures();
  const forceClear = () => {
    useFoodDetailsPopupStore.setState({
      isMouseOverPopup: false,
      isMouseOverTarget: null,
      componentPosition: null,
      mousePosition: null,
      isPopupShown: false,
      props: null,
    });
  };

  return {
    forceClear: forceClear,
    getShowOnHoverProps: (props: FoodDetailsPopupProps) => {
      if (!flags.food_details_popup) {
        return {
          onMouseOver: () => {},
          onMouseLeave: () => {},
          onWheel: () => {},
        };
      }

      return {
        onMouseOver: event => {
          if (useFoodDetailsPopupStore.getState().isPopupShown) {
            return;
          }
          const currPosition = event.currentTarget.getBoundingClientRect();
          useFoodDetailsPopupStore.setState({
            props: props,
            isMouseOverTarget: event.currentTarget,
            componentPosition: currPosition,
            mousePosition: {
              x: event.clientX,
              y: event.clientY,
            },
          });
        },
        onMouseLeave: (event) => {
          useFoodDetailsPopupStore.setState(prev => {
            if (event.currentTarget == prev.isMouseOverTarget) {
              return {
                ...prev,
                isMouseOverTarget: null,
                isPopupShown: false,
              };
            }
            return prev;
          });
        },
        onWheel: () => {
          forceClear();
        },
      } satisfies Pick<React.HTMLAttributes<HTMLElement>, 'onMouseOver' | 'onMouseLeave' | 'onWheel'>;
    },
  };
};

export const FoodDetailsPopupWindow = (
  props: {
    foodDetailsPopupProps: FoodDetailsPopupProps,
    anchorPosition: DOMRect | null,
    mousePosition: { x: number, y: number } | null,
  },
) => {
  const { mousePosition } = props;
  const { internalFoodName, mealItem, externalFoodRes, showRecentMealsWithQueueId, handleMealClick } =
    props.foodDetailsPopupProps;
  const internalFoodDetails = useFoodDetails(internalFoodName || mealItem?.food_name);
  const timeOnPage = useRef<number>(0);
  const internalFoodDetailsRef = useRef<UseFoodRes<FoodDetailsResponse>>(internalFoodDetails);
  const foodDetailsPopup = useFoodDetailsPopup();

  useEffect(() => {
    internalFoodDetailsRef.current = internalFoodDetails;
  }, [internalFoodDetails]);

  useEffect(() => {
    useFoodDetailsPopupStore.setState((prev) => {
      return {
        ...prev,
        isPopupShown: true,
      };
    });

    const interval = setInterval(() => {
      timeOnPage.current += 1;
    }, 1000);

    return () => {
      if (timeOnPage.current == 0) {
        return;
      }

      mixpanel.track('Food Details Popup: Viewed', {
        'Duration (s)': timeOnPage.current,
        'Food name': externalFoodRes?.full_name || mealItem?.food_name || internalFoodName,
        'Food source': externalFoodRes ? externalFoodRes.sourceId : 'ia_db',
        'Hovered over': externalFoodRes ? 'external search' : mealItem ? 'meal item' : 'internal search',
      });
      clearInterval(interval);
    };
  }, []);

  const virtualElemSize = 20;
  const virtualElement: PopperProps['anchorEl'] = {
    getBoundingClientRect: () =>
      props.foodDetailsPopupProps?.anchorTo == 'element' && props.anchorPosition
        ? props.anchorPosition
        : mousePosition
        ? {
          width: virtualElemSize,
          height: virtualElemSize,
          top: mousePosition.y - virtualElemSize / 2,
          right: mousePosition.x + virtualElemSize / 2,
          bottom: mousePosition.y + virtualElemSize / 2,
          left: mousePosition.x - virtualElemSize / 2,
          x: mousePosition.x - virtualElemSize / 2,
          y: mousePosition.y - virtualElemSize / 2,
          toJSON: () => '',
        }
        : {
          width: 0,
          height: 0,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          x: 0,
          y: 0,
          toJSON: () => '',
        },
  };

  const getPreviewComponent = () => {
    if (showRecentMealsWithQueueId && mealItem) {
      return (
        <RecommendedMealsPreview
          foodRes={internalFoodDetails}
          queueItemId={showRecentMealsWithQueueId}
          mealItem={mealItem}
          handleMealClick={handleMealClick}
        />
      );
    }
    if (internalFoodName || mealItem) {
      return <InternalFoodPreview foodRes={internalFoodDetails} mealItem={mealItem} />;
    }
    if (externalFoodRes) {
      return <ExternalFoodPreview extSearchRes={externalFoodRes} />;
    }
  };

  if (!internalFoodName && !externalFoodRes && !mealItem) {
    return null;
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        foodDetailsPopup.forceClear();
      }}
    >
      <Popper
        placement="auto"
        anchorEl={virtualElement}
        open={true}
        sx={{ zIndex: 99999, pointerEvents: 'auto' }}
        modifiers={[{
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: false,
            rootBoundary: 'viewport',
          },
        }]}
      >
        <Paper
          square={false}
          elevation={24}
          sx={{
            width: min([800, window.innerWidth]),
            height: '96vh',
            borderRadius: 5,
            paddingY: 5,
            paddingX: 5,
            overflow: 'scroll',
          }}
        >
          {getPreviewComponent()}
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};

const RecommendedMealsPreview = (
  props: {
    foodRes: UseFoodRes<FoodDetailsResponse>,
    queueItemId: number,
    mealItem: MealItem,
    handleMealClick?: (mealResponse: MealResponse, index: number, source?: string) => void,
  },
) => {
  const { foodRes, queueItemId, mealItem, handleMealClick } = props;
  const ingredientsWhiteboard = useFoodEditorWhiteboardService({
    term: foodRes.food?.name ?? '',
    ndbNumber: foodRes.food?.rxfood_id ?? '',
  }).whiteboardItems.filter((item) => item.type == 'ingredients').pop();
  const { recentMeals, loading: recentMealsLoading } = usePatientMealHistory(
    queueItemId,
    mealItem.food_name,
  );
  const suggestedServing: UsdaMeasure | undefined =
    foodRes.food?.suggested_serving_unit_label && foodRes.food?.suggested_serving_count
      && foodRes.food?.suggested_serving_amount_g
      ? {
        label: foodRes.food?.suggested_serving_unit_label,
        qty: foodRes.food?.suggested_serving_count,
        eqv: foodRes.food?.suggested_serving_amount_g,
      }
      : undefined;

  const mealItemServing: UsdaMeasure | undefined = mealItem
    ? {
      label: mealItem.serving_unit_label,
      qty: mealItem.servings,
      eqv: mealItem.serving_unit_amount * mealItem.servings,
    }
    : undefined;
  const recentMealsByDate = useMemo(() => {
    return _(recentMeals)
      .orderBy(['meal_date', 'meal_time'], ['desc', 'desc'])
      .value();
  }, [recentMeals]);

  if (foodRes.query.isLoading) {
    return <div>'Loading...'</div>;
  }
  if (foodRes.query.isError) {
    return <div>'Error loading food details'</div>;
  }

  return (
    <FoodPreviewGrid
      foodName={foodRes.food?.name ?? ''}
      foodImage={foodRes.food?.food_image_url}
      nutritionRes={foodRes.usda_nutrition}
      ingredients={ingredientsWhiteboard?.text}
      suggestedServing={suggestedServing}
      mealItemServing={mealItemServing}
      customElementTop={recentMeals.length > 0
        ? (
          <RecommendedMeals
            title={`Patient's Recent Meals with ${mealItem.food_name}`}
            mealHistory={recentMealsByDate}
            addItemSource="Recent meals"
            handleClick={handleMealClick}
          />
        )
        : recentMealsLoading
        ? (
          <Stack>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {`Patient's Recent Meals with ${mealItem.food_name}`}
            </Typography>
            <Typography variant="h6" style={{ marginBottom: '25px' }}>
              Loading recent meals...
            </Typography>
          </Stack>
        )
        : (
          <Stack>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {`Patient's Recent Meals with ${mealItem.food_name}`}
            </Typography>
            <Typography variant="h6" style={{ marginBottom: '25px' }}>
              No recent meals
            </Typography>
          </Stack>
        )}
      foodUrl={foodRes.food?.name ? getFoodEditorUrl(foodRes.food?.name) : undefined}
      extraInfo={foodRes.food?.custom_tip ?? ''}
    />
  );
};

const InternalFoodPreview = (
  props: { foodRes: UseFoodRes<FoodDetailsResponse>, mealItem?: MealItem },
) => {
  const { foodRes, mealItem } = props;
  const ingredientsWhiteboard = useFoodEditorWhiteboardService({
    term: foodRes.food?.name ?? '',
    ndbNumber: foodRes.food?.rxfood_id ?? '',
  }).whiteboardItems.filter((item) => item.type == 'ingredients').pop();
  const relevantQueuesQuery = useQuery(['relevant_queues', foodRes.food?.name], async () => {
    if (!foodRes.food?.name) {
      return {
        queues: [],
      };
    }

    const queueRes = await foodApi.appApiFoodFoodWhiteboardGetRelevantQueuesQuery({
      food_name: foodRes.food?.name,
    }).then(res => res.data.reverse());

    return {
      queues: queueRes,
    };
  }, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const suggestedServing: UsdaMeasure | undefined =
    foodRes.food?.suggested_serving_unit_label && foodRes.food?.suggested_serving_count
      && foodRes.food?.suggested_serving_amount_g
      ? {
        label: foodRes.food?.suggested_serving_unit_label,
        qty: foodRes.food?.suggested_serving_count,
        eqv: foodRes.food?.suggested_serving_amount_g,
      }
      : undefined;

  const mealItemServing: UsdaMeasure | undefined = mealItem
    ? {
      label: mealItem.serving_unit_label,
      qty: mealItem.servings,
      eqv: mealItem.serving_unit_amount * mealItem.servings,
    }
    : undefined;

  if (foodRes.query.isLoading) {
    return <div>'Loading...'</div>;
  }
  if (foodRes.query.isError) {
    return <div>'Error loading food details'</div>;
  }

  return (
    <FoodPreviewGrid
      foodName={foodRes.food?.name ?? ''}
      foodImage={foodRes.food?.food_image_url}
      nutritionRes={foodRes.usda_nutrition}
      ingredients={ingredientsWhiteboard?.text}
      suggestedServing={suggestedServing}
      mealItemServing={mealItemServing}
      customElementBottom={
        <>
          <Typography variant="h4">Relevant Queues (All Patients)</Typography>
          <RelevantQueuesTileView relevantQueuesQuery={relevantQueuesQuery} />
        </>
      }
      foodUrl={foodRes.food?.name ? getFoodEditorUrl(foodRes.food?.name) : undefined}
      extraInfo={foodRes.food?.custom_tip ?? ''}
    />
  );
};

const ExternalFoodPreview = (props: { extSearchRes: ExternalFoodSearchResult }) => {
  const { extSearchRes } = props;

  return (
    <FoodPreviewGrid
      foodName={extSearchRes.full_name}
      foodImage={extSearchRes.thumbUrl}
      ingredients=""
      nutritionRes={extSearchRes.nutrients}
      externalDefaultServing={{
        label: extSearchRes.serving_unit_label,
        qty: extSearchRes.servings,
        eqv: (extSearchRes.serving_unit_amount ?? 0) * extSearchRes.servings,
      }}
      foodUrl={extSearchRes.sourceUrl ?? undefined}
    />
  );
};

const FoodPreviewGrid = (
  props: {
    foodName: string,
    foodImage?: string | null,
    nutritionRes?: UsdaNutritionResponse,
    ingredients?: string,
    suggestedServing?: UsdaMeasure,
    mealItemServing?: UsdaMeasure,
    externalDefaultServing?: UsdaMeasure,
    customElementTop?: JSX.Element,
    customElementBottom?: JSX.Element,
    foodUrl?: string,
    extraInfo?: string,
  },
) => {
  const foodDetailsPopup = useFoodDetailsPopup();

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <Stack>
          <Typography variant="h1" fontWeight="bold">
            {props.foodName}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack direction="row" justifyContent="end">
          <IconButton
            color="primary"
            disabled={!props.foodUrl}
            onClick={() => {
              window.open(props.foodUrl);
            }}
          >
            <OpenInNewIcon />
          </IconButton>
          <IconButton color="error" onClick={foodDetailsPopup.forceClear}>
            <CloseOutlined />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={12}>{props.customElementTop}</Grid>
      <Grid item xs={6}>
        {props.foodImage
          ? (
            <div style={{ width: '100%' }}>
              <img
                src={props.foodImage ?? undefined}
                style={{ width: '100%', height: 'auto', maxHeight: '100%', objectFit: 'contain' }}
              />
            </div>
          )
          : (
            <Typography>
              No Food Image
            </Typography>
          )}
        {!!props.extraInfo && <Typography>Custom Tip: {props.extraInfo}</Typography>}
      </Grid>
      <Grid item xs={6}>
        <FoodNFT
          nutritionRes={props.nutritionRes}
          ingredents={props.ingredients}
          suggestedServing={props.suggestedServing}
          mealItemServing={props.mealItemServing}
          externalDefaultServing={props.externalDefaultServing}
        />
      </Grid>
      <Grid item xs={12}>{props.customElementBottom}</Grid>
    </Grid>
  );
};

const NFT_CORE_NUTRIENTS = ['protein_g', 'cholesterol_mg', 'sodium_mg'];
const NFT_MICRO_NUTRIENTS = ['vitamin_c_mg', 'calcium_mg', 'iron_mg'];

export const FoodNFT = (
  props: {
    nutritionRes?: UsdaNutritionResponse,
    ingredents?: string,
    suggestedServing?: UsdaMeasure,
    mealItemServing?: UsdaMeasure,
    externalDefaultServing?: UsdaMeasure,
  },
) => {
  const { nutritionRes } = props;
  const [servingSize, setServingSize] = useState<UsdaMeasure>(
    props.mealItemServing || props.suggestedServing || props.externalDefaultServing
      || { label: 'g', qty: 100, eqv: 100 },
  );
  const scaleFactor = servingSize.eqv / 100;

  const getFieldsForRender = (field: keyof UsdaNutritionResponse) => {
    const value = nutritionRes?.[field];
    const fieldSplit = field.split('_');
    const unit = fieldSplit.pop();
    const label = fieldSplit.map((str) => str.charAt(0).toUpperCase() + str.slice(1)).join(' ');

    return {
      label: label,
      value: value,
      unit: '' + unit,
    };
  };

  const renderRow = (
    opts: {
      label: string,
      value: number | null | undefined,
      unit: string,
      subField?: boolean,
      bold?: boolean,
      skipUnderline?: boolean,
    },
  ) => {
    const { label, value, unit, subField, bold, skipUnderline } = opts;

    const textStyle: TypographyPropsVariantOverrides = {
      variant: 'body1',
      sx: { fontWeight: bold ? 'bold' : 'normal' },
    };

    return (
      <Stack
        key={label}
        direction="row"
        justifyContent="space-between"
        sx={{ borderBottom: !skipUnderline ? '1px solid black' : undefined }}
      >
        <div style={{ display: 'flex' }}>
          {subField && <Typography {...textStyle}>&nbsp;&nbsp;</Typography>}
          <Typography {...textStyle}>
            {label}
          </Typography>
        </div>
        <Typography {...textStyle} align="right">
          {typeof value === 'number' ? `${formatNumber(value * scaleFactor, 1)} ${unit}` : '-'}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack>
      <Box
        sx={{
          width: '100%',
          border: '2px solid black',
          padding: '5px',
          fontFamily: '"Helvetica Neue"',
          marginBottom: '8px',
        }}
      >
        <Typography
          variant="h1"
          component="div"
          align="center"
          fontWeight="bold"
          sx={{ borderBottom: '1px solid black', marginBottom: '1px' }}
        >
          Nutrition Facts
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" fontWeight="bold">
            Serving Size
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            {servingSize.label == 'g'
              ? formatNumber(servingSize.qty, 1) + servingSize.label
              : `${formatNumber(servingSize.qty, 1)} ${servingSize.label} (${formatNumber(servingSize.eqv, 1)}g)`}
          </Typography>
        </Stack>
        <Typography sx={{ borderBottom: '8px solid black' }} />
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography variant="body1" fontWeight="bold" sx={{ marginBottom: -1 }}>
              Amount per serving
            </Typography>
            <Typography variant="h2" fontWeight="bold">
              Calories
            </Typography>
          </Stack>
          <Stack justifyContent="end">
            <Typography variant="h1" fontWeight="bold" align="right">
              {nutritionRes?.energy_kcal ? formatNumber(nutritionRes?.energy_kcal * scaleFactor, 0) : 0}
            </Typography>
          </Stack>
        </Stack>
        <Typography sx={{ borderBottom: '4px solid black' }} />
        {renderRow({
          label: 'Fat',
          value: nutritionRes?.fat_g,
          unit: 'g',
          bold: true,
        })}
        {[{ label: 'Saturated Fat', value: nutritionRes?.fattyacids_totalsaturated_g, unit: 'g' }, {
          label: 'Trans Fat',
          value: nutritionRes?.fattyacids_totaltrans_g,
          unit: 'g',
        }].map((item) =>
          renderRow({
            label: item.label,
            value: item.value,
            unit: item.unit,
            subField: true,
          })
        )}
        {renderRow({
          label: 'Carbohydrate',
          value: nutritionRes?.carbohydrate_g,
          unit: 'g',
          bold: true,
        })}
        {[{ label: 'Dietary Fibre', value: nutritionRes?.fiber_g, unit: 'g' }, {
          label: 'Sugars',
          value: nutritionRes?.sugars_g,
          unit: 'g',
        }].map((item) =>
          renderRow({
            label: item.label,
            value: item.value,
            unit: item.unit,
            subField: true,
          })
        )}
        {NFT_CORE_NUTRIENTS.map((item, idx) =>
          renderRow({
            ...getFieldsForRender(item as keyof UsdaNutritionResponse),
            bold: true,
            skipUnderline: idx == NFT_CORE_NUTRIENTS.length - 1,
          })
        )}
        <Typography sx={{ borderBottom: '8px solid black' }} />
        {NFT_MICRO_NUTRIENTS.map((item, idx) =>
          renderRow({
            ...getFieldsForRender(item as keyof UsdaNutritionResponse),
            skipUnderline: idx == NFT_MICRO_NUTRIENTS.length - 1,
          })
        )}
        {!!props.ingredents && (
          <span>
            <Typography sx={{ borderBottom: '4px solid black' }} />
            <Typography variant="body1" display="inline" fontWeight="bold">
              Ingredents:&nbsp;
            </Typography>
            <Typography variant="body1" display="inline">
              {props.ingredents}
            </Typography>
          </span>
        )}
      </Box>
      <ButtonGroup variant="outlined" fullWidth>
        <Button
          onClick={() => {
            setServingSize({
              label: 'g',
              qty: 100,
              eqv: 100,
            });
          }}
        >
          100g
        </Button>
        {!!props.suggestedServing && (
          <Button
            onClick={() => {
              setServingSize(props.suggestedServing!);
            }}
          >
            Suggested Serving
          </Button>
        )}
        {!!props.mealItemServing && (
          <Button
            onClick={() => {
              setServingSize({
                label: props.mealItemServing!.label,
                qty: props.mealItemServing!.qty,
                eqv: props.mealItemServing!.eqv,
              });
            }}
          >
            Meal Item Serving
          </Button>
        )}
        {!!props.externalDefaultServing && (
          <Button
            onClick={() => {
              setServingSize({
                label: props.externalDefaultServing!.label,
                qty: props.externalDefaultServing!.qty,
                eqv: props.externalDefaultServing!.eqv,
              });
            }}
          >
            Ext. Source Serving
          </Button>
        )}
      </ButtonGroup>
      <Stack direction="row" spacing={2}>
        <Slider
          value={servingSize.eqv}
          valueLabelDisplay="auto"
          step={25}
          min={0}
          max={1000}
          marks={[
            { value: 0, label: '0' },
            { value: 1000, label: '1000g' },
          ]}
          onChange={(event: Event, newValue: number | number[]) => {
            setServingSize({
              label: 'g',
              qty: newValue as number,
              eqv: newValue as number,
            });
          }}
        />
      </Stack>
    </Stack>
  );
};

export const DelayRender = (props: { children: ReactNode, delaySeconds?: number }): JSX.Element => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, props.delaySeconds ? props.delaySeconds * 1000 : 0);
    return () => clearTimeout(timer);
  }, [props.delaySeconds]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isShown ? <>{props.children}</> : <></>;
};
