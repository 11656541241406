import { LeftOutlined } from '@ant-design/icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpandLess, ExpandMore, UnfoldMore } from '@mui/icons-material';
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell as _TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { mealApi, reportingApi } from 'api';
import {
  CcColumnsRequest,
  MealItemCustomAddonResponse,
  MealItemResponse,
  MealResponse,
  PatientReportErrorField,
  PatientReportErrorFlag,
  PatientReportResponse,
  UsdaNutritionResponse,
} from 'api/generated/MNT';
import { Capabilities } from 'auth-capabilities';
import IconButton from 'components/@extended/IconButton';
import { ImageFromQuery, useAuthenticatedBlobQuery } from 'components/AuthenticatedImage';
import { useFoodDetailsPopup } from 'components/FoodDetailsPopup';
import MainCard from 'components/MainCard';
import { PatientID } from 'components/PatientID';
import { PopoverImageViewer, usePopoverImage } from 'components/PopoverImageViewer';
import {
  rxTableCellDefaultRenderer,
  RxTableCellProps,
  RxTableColumn,
  RxTableColumnSelect,
  RxTableData,
  useRxTableData,
} from 'components/RxTable';
import { useAuth } from 'context/appContext';
import { useSetPageTitle } from 'hooks/useSetPageTitle';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useQueryNeverRefetch } from 'utils';
import { mealItemGetNutrientValue } from 'utils/mealItems';
import { formatMealItemSizing } from './QueueItem/meal-builder/MealBuilder';
import { checkPctEaten, getFoodEditorUrl, getItemWeightWithoutAddons } from './QueueItem/meal-builder/MealSummary';
import { formatPercent } from './QueueItem/services/QueueItemEditorService';

const TableCell = styled(_TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    verticalAlign: 'top',
  },
}));

const HeaderTableCell = styled(_TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    position: 'sticky',
  },
}));

const PatientReportItemHeader = (props: {
  report: PatientReportResponse,
}) => {
  const { report } = props;
  const navigate = useNavigate();
  return (
    <Stack direction="row" spacing={1.5}>
      <IconButton variant="outlined" color="primary" onClick={() => navigate(-1)}>
        <LeftOutlined />
      </IconButton>
      <Typography variant="h2">
        Report: #{report.patient_report_id}{' '}
        <span style={{ opacity: report.status == 'sent' ? 0.7 : undefined }}>
          ({report.status})
        </span>
      </Typography>
      <Box flexGrow={1} />
      <Stack direction="row" spacing={2}>
        <Typography>
          <b>Created by:&nbsp;</b>
          {report.created_by_user_id == 1 ? 'System' : 'Clinician'}
        </Typography>
        <Stack>
          <Typography>
            <b>Patient:&nbsp;</b>
            <PatientID userId={report.patient_id} isPriority={report.patient_is_priority} />
          </Typography>
          <Typography>
            <b>Country:&nbsp;</b>
            {report.patient_country_of_residence}
          </Typography>
        </Stack>
        <Stack>
          <Typography>
            <b>Date:&nbsp;</b>
            {report.report_date}
          </Typography>
          <Typography>
            <b>Clinic:&nbsp;</b>
            {report.clinic_name} ({report.clinic_id})
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const PatientReportMealTableMealImage = (props: {
  meal: MealResponse,
}) => {
  const { meal } = props;
  const popoverImage = usePopoverImage();
  const img = useAuthenticatedBlobQuery(meal.meal_photo_resized_url);

  if (!meal.meal_photo_resized_url) {
    return null;
  }

  return (
    <ImageFromQuery
      img={img}
      onClick={() => popoverImage.show({ src: img.src })}
      style={{
        maxWidth: 75,
        maxHeight: 75,
        objectFit: 'contain',
        cursor: 'pointer',
      }}
    />
  );
};

const PatientReportErrorFieldCard = (props: {
  field: PatientReportErrorField,
}) => {
  const { field } = props;
  const href = field.object_class == 'DBMeal'
    ? `/meal/${field.value}`
    : field.field == 'food_name'
    ? `/foods/table?food-database-table-filter=%7B%22food_name%22%3A%7B%22eq%22%3A${
      encodeURIComponent(JSON.stringify(field.value ?? ''))
    }%7D%7D`
    : null;
  return (
    <Card variant="outlined">
      <CardHeader
        title={field.value}
        subheader={field.object_class == 'PatientRdiNutrientResponse'
          ? field.field?.split('.')?.[1]
          : field.object_class + '.' + field.field}
        action={href && (
          <Link to={href} target="_blank">
            <IconButton size="small">
              <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" color="black" />
            </IconButton>
          </Link>
        )}
      />
    </Card>
  );
};

const PatientReportErrorFlagFields = (props: {
  flag: PatientReportErrorFlag,
}) => {
  const { flag } = props;
  const messageMealId = flag.message?.match(/Meal ID: (\d+)/i)?.[1];
  const messageMealIdFields: Array<PatientReportErrorField> = [];
  if (messageMealId) {
    messageMealIdFields.push({
      'field': 'meal_id',
      'object_class': 'DBMeal',
      'value': messageMealId,
    });
  }
  const fields = [
    ...messageMealIdFields,
    ...(flag.fields || []),
  ];
  if (!fields.length) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1}>
      {fields.map((field, index) => <PatientReportErrorFieldCard key={index} field={field} />)}
    </Stack>
  );
};

const MutationError = (props: {
  error: any,
}) => {
  const message = props.error?.response?.data?.message;
  return (
    <Alert severity="error" variant="outlined">
      {message ?? ('' + props.error)}
    </Alert>
  );
};

const PatientReportErrorFlagsTable = (props: {
  report: PatientReportResponse,
}) => {
  const errorFlags = props.report.error_flags;
  if (!errorFlags || !Array.isArray(errorFlags)) {
    return (
      null
    );
  }

  const errorFlagsSorted = _.sortBy(errorFlags, flag => -(flag.level ?? 0));

  const levelToSeverity = (level: number): AlertColor => {
    if (level >= 50) {
      return 'error';
    }

    if (level >= 40) {
      return 'warning';
    }

    return 'info';
  };

  const formatMessage = (message: string | null | undefined) => {
    if (!message) {
      return '';
    }

    let prefix = message.match(/^(.+?)(:|\.|Meal ID)/)?.[1] ?? message;
    if (prefix.endsWith('Meal ID')) {
      prefix = prefix.slice(0, -'Meal ID'.length);
    }
    const suffix = message
      .slice(prefix.length)
      .replace(/^[.:]\s*/, '')
      .replace(/(Meal|Meal Item) ID: (\d+);?/g, '')
      .trim();

    return (
      <>
        <Typography variant="h4">{prefix}</Typography>
        {!!suffix && <Typography>{suffix}</Typography>}
      </>
    );
  };

  return (
    <Stack spacing={1}>
      {errorFlagsSorted.map((flag, index) => (
        <Alert key={index} severity={levelToSeverity(flag.level ?? 0)} variant="border">
          <Stack spacing={1}>
            {formatMessage(flag.message)}
            <PatientReportErrorFlagFields flag={flag} />
          </Stack>
        </Alert>
      ))}
    </Stack>
  );
};

const PatientReportActions = (props: {
  report: PatientReportResponse,
}) => {
  const { report } = props;
  const { hasAuth } = useAuth();

  const [regenFilterDates, setRegenFilterDates] = useState(true);
  const regenMutation = useMutation(async () => {
    const res = await reportingApi.appApiPatientReportsApiPostRegenerate({
      patient_report_id: report.patient_report_id!,
      filter_meal_dates: regenFilterDates,
      as_background_task: true,
    });
    alert(`Regeneration result: ${JSON.stringify(res.data)}`);
  }, {
    onSuccess: () => {
      window.location.reload();
    },
  });

  const sendMutation = useMutation(async () => {
    await reportingApi.appApiPatientReportsApiPutFoodReport({
      patient_id: report.patient_id!,
      clinic_id: report.clinic_id!,
      hospital_id: report.hospital_id!,
      patient_report_id: report.patient_report_id!,
      status: 'sent',
    });
  }, {
    onSuccess: () => {
      window.location.reload();
    },
  });

  const clearErrorFlagsMutation = useMutation(async () => {
    await reportingApi.appApiPatientReportsApiPostApproveFoodReport({
      patient_id: report.patient_id!,
      clinic_id: report.clinic_id!,
      hospital_id: report.hospital_id!,
      patient_report_id: report.patient_report_id!,
    });
  }, {
    onSuccess: () => {
      window.location.reload();
    },
  });

  const confirmAction = (message: string, cb: () => void) => {
    const res = window.prompt(message + '\n' + 'Type "yes" to confirm.');
    if (res != 'yes') {
      return;
    }
    cb();
  };

  const [newReportDates, setNewReportDates] = useState({
    date_since: report.date_since,
    date_until: report.date_until,
  });
  const updateMutation = useMutation(async () => {
    alert('TODO: figure out how to update report dates');
  });

  const reportPdfQuery = useQuery(['patient-report-pdf-link', report.patient_report_id], async () => {
    const res = await reportingApi.appApiPatientReportsApiGetPatientRxfoodReportSecureLink({
      patient_id: report.patient_id!,
      patient_report_id: report.patient_report_id!,
    });
    return res.data;
  }, useQueryNeverRefetch);
  const [showPdfUrl, setShowPdfUrl] = useState(null as string | null);
  const pdfDataQuery = useAuthenticatedBlobQuery(showPdfUrl);
  useEffect(() => {
    if (pdfDataQuery.src) {
      try {
        window.open(pdfDataQuery.src, '_blank');
      } catch (e) {
        console.error('Error showing PDF:', e);
      }
    }
  }, [pdfDataQuery.src]);

  return (
    <Stack direction="row" spacing={3}>
      <Stack spacing={1}>
        <Typography variant="h5">Actions</Typography>
        <Button
          variant="contained"
          onClick={() => {
            setShowPdfUrl(null);
            setTimeout(() => setShowPdfUrl(reportPdfQuery.data?.url!), 10);
          }}
          disabled={!reportPdfQuery.data?.url || (
            pdfDataQuery.isEnabled && pdfDataQuery.isLoading
          )}
        >
          Open Report PDF
        </Button>

        {hasAuth(Capabilities.patientReportEdit) && (
          <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
              <Button variant="contained" onClick={() => regenMutation.mutate()} disabled={regenMutation.isLoading}>
                Regenerate Report
              </Button>
              <FormControlLabel
                control={<Checkbox checked={regenFilterDates} onChange={v => setRegenFilterDates(v.target.checked)} />}
                label="Exclude days with few meals"
              />
            </Stack>
            {regenMutation.isError && <MutationError error={regenMutation.error} />}
          </Stack>
        )}

        {hasAuth(Capabilities.patientReportEdit) && (
          <Stack spacing={1}>
            <Button
              variant="contained"
              onClick={() => confirmAction('Clear error flags?', () => clearErrorFlagsMutation.mutate())}
              disabled={clearErrorFlagsMutation.isLoading || report.ignore_error_flags || report.status == 'sent'}
            >
              {report.ignore_error_flags ? 'Error flags ignored' : 'Ignore error flags'}
            </Button>
            {clearErrorFlagsMutation.isError && <MutationError error={clearErrorFlagsMutation.error} />}
          </Stack>
        )}

        {hasAuth(Capabilities.patientReportEdit) && (
          <Stack spacing={1}>
            <Button
              variant="contained"
              onClick={() => confirmAction('Send report to patient?', () => sendMutation.mutate())}
              disabled={sendMutation.isLoading || report.status == 'sent'}
            >
              Send Report
            </Button>
            {sendMutation.isError && <MutationError error={sendMutation.error} />}
          </Stack>
        )}
      </Stack>

      <Stack spacing={1}>
        <Typography variant="h5">Dates</Typography>
        <Stack direction="row" spacing={1}>
          <TextField
            label="First Day"
            type="date"
            value={newReportDates.date_since}
            onChange={e =>
              setNewReportDates({
                ...newReportDates,
                date_since: e.target.value,
              })}
            InputLabelProps={{
              shrink: true,
            }}
            disabled
          />

          <TextField
            label="Last Day"
            type="date"
            value={newReportDates.date_until}
            onChange={e =>
              setNewReportDates({
                ...newReportDates,
                date_until: e.target.value,
              })}
            InputLabelProps={{
              shrink: true,
            }}
            disabled
          />
        </Stack>
      </Stack>

      <Box flexGrow={1} />
    </Stack>
  );
};

export const PatientReportItemPage = () => {
  const { itemId } = useParams();
  const theme = useTheme();
  const mealRowIndex = useRef(0);
  mealRowIndex.current = 0;
  const itemRowIndex = useRef(1);
  itemRowIndex.current = 1;
  type ReportTableRowFields = MealItemResponse & UsdaNutritionResponse & CcColumnsRequest;
  type ColumnGroups = 'macro' | 'micro' | 'cc';
  const [columnGroup, setColumnGroup] = useState<ColumnGroups>('macro');
  const [disaggregateMealItems, setDisaggregateMealItems] = useState(false);
  const [groupMealItems, setGroupMealItems] = useState(true);
  const foodDetailsPopup = useFoodDetailsPopup();

  useSetPageTitle('report #', itemId);

  const query = useQuery(['patient-report', itemId], async () => {
    if (!itemId) {
      return null;
    }

    const res = await reportingApi.appApiPatientReportsApiGetPatientReport({
      patient_report_id: +itemId,
    });
    return res.data;
  });

  const mealData = useRxTableData({
    queryKey: ['patient-meals', itemId ?? '', disaggregateMealItems ? 'disagg' : 'agg'].join('-'),
    apiEndpoint: args => {
      return mealApi.appApiMealGetMealsTable({
        patient_id: query.data!.patient_id!,
        nutrition_response_level: 'complete',
        disaggregate_meal_items: disaggregateMealItems,
        ...args,
      });
    },
    initialFilter: {
      meal_date: {
        gte: query.data?.date_since,
        lte: query.data?.date_until,
      },
    },
    queryOptions: {
      enabled: !!query.data?.patient_id,
      staleTime: Infinity,
    },
  });

  const [sortOptions, setSortOptions] = useState({ field: '' as keyof UsdaNutritionResponse, direction: '' });
  const handleSortColumn = (field: keyof UsdaNutritionResponse) => {
    const newSortField = field != sortOptions.field;
    setSortOptions({
      field: field,
      direction: newSortField ? 'asc' : sortOptions.direction == 'asc' ? 'desc' : 'asc',
    });
  };

  const mealsGrouped: Array<{ header: string, meals: MealResponse[], length: number }> = useMemo(() => {
    const meals = mealData.rows || [];
    meals.sort((a, b) => b.meal_date > a.meal_date ? 1 : -1);
    const res = [] as Array<{ header: string, meals: MealResponse[], length: number }>;

    let last: any = null;
    meals.forEach(meal => {
      const mealDate = moment(meal.meal_date).format('YYYY-MM-DD');
      const mealHeader = `${mealDate}`;

      if (!groupMealItems) {
        meal.meal_items.forEach(item => {
          const ioslateMealItem: MealResponse = { ...meal, meal_items: [item] };
          res.push({ header: mealHeader, meals: [ioslateMealItem], length: 1 + (item.custom_addons?.length || 0) });
        });
        return;
      }

      if (last?.header == mealHeader) {
        last.meals.push(meal);
        last.length += meal.meal_items.length;
        meal.meal_items.forEach(item => {
          last.length += item.custom_addons?.length || 0;
        });
        return;
      }
      let addonLength: number = 0;
      meal.meal_items.forEach(item => {
        addonLength += item.custom_addons?.length || 0;
      });
      const length = meal.meal_items.length + addonLength;
      last = { header: mealHeader, meals: [meal], length: length };
      res.push(last);
    });

    const sortMealItemsByNutrient = (items: { a?: UsdaNutritionResponse, b?: UsdaNutritionResponse }) => {
      const { a, b } = items;

      if (!a || !b) {
        return -1;
      }

      const validA = typeof a[sortOptions.field] === 'number';
      const validB = typeof b[sortOptions.field] === 'number';
      const validComparison = validA && validB;

      if (validComparison && sortOptions.direction == 'asc') {
        return b[sortOptions.field]! > a[sortOptions.field]! ? 1 : -1;
      } else if (validComparison && sortOptions.direction == 'desc') {
        return b[sortOptions.field]! < a[sortOptions.field]! ? 1 : -1;
      }
      return -1;
    };

    if (sortOptions.field) {
      if (groupMealItems) {
        res.forEach(gm => {
          gm.meals.forEach(meal => {
            meal.meal_items.sort((a, b) => {
              return sortMealItemsByNutrient({ a: a, b: b });
            });
          });
        });
      } else {
        res.sort((a, b) => {
          const mealItemA = a.meals[0].meal_items[0];
          const mealItemB = b.meals[0].meal_items[0];
          return sortMealItemsByNutrient({ a: mealItemA.nutrition_response, b: mealItemB.nutrition_response });
        });
      }
    }

    return res;
  }, [mealData.rows, sortOptions, groupMealItems]);

  const renderAddonName = (row: { item: MealItemCustomAddonResponse, parent: MealItemResponse }) => {
    return (
      <>
        <Typography variant="caption">
          {!!row.parent && '↳'}
          {row.item.food_name}
        </Typography>
        &nbsp;
        <Link
          style={{ cursor: 'pointer' }}
          to={getFoodEditorUrl(row.item.food_name)}
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            size="sm"
            color="black"
          />
        </Link>
      </>
    );
  };

  const renderAddonSizing = (row: { item: MealItemCustomAddonResponse, parent: MealItemResponse }) => {
    const pctEaten = row.parent?.percent_eaten ?? 1;
    const formattedPctEaten = formatPercent(pctEaten);

    const displayPctEaten = [
      formattedPctEaten != '100%' && String.fromCharCode(0x00D7) + formattedPctEaten,
    ].filter(Boolean);

    return (
      <>
        <span
          style={{ whiteSpace: 'nowrap', float: 'left' }}
        >
          {row.item.servings}&times;{row.item.serving_unit_amount}g{!!displayPctEaten.length
            && displayPctEaten}&nbsp;
        </span>
        <span
          style={{ whiteSpace: 'nowrap' }}
        >
          ({row.item.serving_unit_label})
        </span>
      </>
    );
  };

  const renderMealItemWeight = (item: MealItemResponse) => {
    const pctEaten = item.percent_eaten ?? 1;

    const weight = getItemWeightWithoutAddons({
      serving_unit_amount: item.serving_unit_amount,
      servings: item.servings || 1,
    }, checkPctEaten(pctEaten));

    return (
      <span>
        {weight.toFixed(0)}g
      </span>
    );
  };

  const renderAddonWeight = (row: { item: MealItemCustomAddonResponse, parent: MealItemResponse }) => {
    const pctEaten = row.parent.percent_eaten ?? 1;

    const weight = getItemWeightWithoutAddons({
      serving_unit_amount: row.item.serving_unit_amount,
      servings: row.item.servings || 1,
    }, checkPctEaten(pctEaten));

    return (
      <span>
        {`${weight.toFixed(0)}g`}
      </span>
    );
  };

  const renderMealItem = (
    item: MealItemResponse,
    index: number,
    columns: RxTableColumn<ReportTableRowFields>[],
  ) => {
    itemRowIndex.current++;
    return (
      <TableRow
        key={index}
        style={{
          backgroundColor: itemRowIndex.current % 2 != 0
            ? theme.palette.grey[50]
            : theme.palette.secondary.lighter,
        }}
      >
        {columns.map((column, index) => {
          const renderer = column.cellRenderer || rxTableCellDefaultRenderer;
          // Data is null here as table is not tied to a query //TODO fix the data field when swapping to RxTable
          const value = renderer({
            row: item,
            column: column,
            data: null as unknown as RxTableData<ReportTableRowFields>,
          });
          return (
            <TableCell
              key={index}
              style={column.id == 'food_name' ? { ...tableStickyCellStyle } : {}}
            >
              {!item.note && value}
              {!!item.note && (
                <Tooltip title={item.note}>
                  <span>{value}</span>
                </Tooltip>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const renderAddon = (
    item: MealItemCustomAddonResponse,
    parent: MealItemResponse,
    columns: RxTableColumn<{ item: MealItemCustomAddonResponse, parent: MealItemResponse }>[],
  ) => {
    itemRowIndex.current++;
    return (
      <TableRow
        key={item.id}
        style={{
          backgroundColor: itemRowIndex.current % 2 != 0 ? theme.palette.grey[50] : theme.palette.secondary.lighter,
        }}
      >
        {columns.map((column, index) => {
          const renderer = column.cellRenderer || rxTableCellDefaultRenderer;
          // Data is null here as table is not tied to a query //TODO fix the data field when swapping to RxTable
          const value = renderer({
            row: { item, parent },
            column: column,
            data: null as unknown as RxTableData<{ item: MealItemCustomAddonResponse, parent: MealItemResponse }>,
          });
          return (
            <TableCell
              key={index}
              colSpan={index == columns.length - 1 ? 6 : 1}
              style={column.id == 'food_name' ? { ...tableStickyCellStyle } : {}}
            >
              {value}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const mealItemColumns: RxTableColumn<ReportTableRowFields>[] = React.useMemo(() => {
    const renderMealItemName = (item: MealItemResponse) => {
      return (
        <div {...foodDetailsPopup.getShowOnHoverProps({ mealItem: item, anchorTo: 'element' })}>
          {item.food_name_alias ?? item.food_name}
          &nbsp;
          <Link
            style={{ cursor: 'pointer' }}
            to={getFoodEditorUrl(item.food_name)}
            target="_blank"
          >
            <FontAwesomeIcon
              icon={faExternalLinkAlt}
              size="sm"
              color="black"
            />
          </Link>
          {item.food_name_alias && (
            <>
              <br />
              <Typography variant="caption" sx={{ color: 'gray' }}>
                &#40;"{item.food_name}"&#41;
              </Typography>
            </>
          )}
          {item.note && <div style={{ fontSize: 12 }}>"{item.note}"</div>}
        </div>
      );
    };

    const nutrientCellRenderer = (props: RxTableCellProps<ReportTableRowFields>) => {
      const nutrient = props.column.field;

      const val = mealItemGetNutrientValue({
        item: props.row as MealItemResponse,
        foodNutrients: null,
        nutrient: nutrient as any,
      });

      // only get nutrient from usda last--this gets overridden by patient- or reviewer-entered values
      if (
        !val.value && !!nutrient && (microTerms.includes(nutrient as string) || macroTerms.includes(nutrient as string))
      ) {
        return <span>{getNutrientValueStrfromUSDA(props.row, nutrient as any)}</span>;
      }

      return <span>{val.valueStr}</span>;
    };

    const getNutrientValueStrfromUSDA = (
      item: ReportTableRowFields,
      nutrient: keyof ReportTableRowFields,
    ) => {
      const nutrientVal = (item.nutrition_response as any)?.[nutrient];
      if (typeof nutrientVal !== 'number' || Number.isNaN(nutrientVal)) {
        return '-';
      }

      const nutrientParts = (nutrient as string).split('_');
      const nutrientUnit = nutrientParts[nutrientParts.length - 1];

      return `${nutrientVal.toFixed(1)}${nutrientUnit}`;
    };

    const ccColCellRenderer = (props: RxTableCellProps<ReportTableRowFields>) => {
      const cc = props.column.field;
      const ccResponse = props.row.cc_columns;

      if (!cc) {
        return <span />;
      }

      const ccVal = (ccResponse as any)?.[cc];
      if (!ccVal) {
        return <span />;
      }
      return <span style={{ display: 'block', textAlign: 'center' }}>✓</span>;
    };

    const formatNutrientFieldHeader = (field: keyof ReportTableRowFields) => {
      const fieldStr = field as string;
      const fieldStrParts = fieldStr.split('_');

      if (fieldStrParts[0] == 'fattyacids') {
        const typeOfFat = fieldStrParts[1].split('total')[1];
        return _.capitalize(typeOfFat) + ' '
          + `fat (${fieldStrParts[fieldStrParts.length - 1]})`;
      }

      return _.capitalize(fieldStrParts.splice(0, fieldStrParts.length - 1).join(' ')) + ' '
        + `(${fieldStrParts[fieldStrParts.length - 1]})`;
    };

    const formatCCFieldHeader = (field: keyof ReportTableRowFields) => {
      const fieldStr = field as string;
      const fieldStrParts = fieldStr.split('_');
      return _.capitalize(fieldStrParts.splice(1, fieldStrParts.length - 1).join(' '));
    };

    const macroTerms = [
      'energy_kcal',
      'fat_g',
      'protein_g',
      'fiber_g',
      'netcarb_g',
      'carbohydrate_g',
      'fattyacids_totaltrans_g',
      'fattyacids_totalsaturated_g',
      'fattyacids_totalmonounsaturated_g',
      'fattyacids_totalpolyunsaturated_g',
    ];
    const macroFields: (keyof ReportTableRowFields)[] = macroTerms.map(n => n as keyof ReportTableRowFields);

    const microTerms = [
      'calcium_mg',
      'iron_mg',
      'magnesium_mg',
      'phosphorus_mg',
      'potassium_mg',
      'sodium_mg',
      'zinc_mg',
      'vitamin_a_iu',
      'vitamin_a_rae_ug',
      'vitamin_e_mg',
      'vitamin_d_iu',
      'vitamin_d2d3_ug',
      'vitamin_c_mg',
      'thiamin_mg',
      'riboflavin_mg',
      'niacin_mg',
      'vitamin_b6_mg',
      'vitamin_b12_ug',
      'vitamin_k_ug',
      'folate_dfe_ug',
      'cholesterol_mg',
      'selenium_mcg',
      'pantothenic_acid_mg',
      'choline_mg',
      'omega_three_fatty_acids_g',
    ];
    const microFields: (keyof ReportTableRowFields)[] = microTerms.map(n => n as keyof ReportTableRowFields);

    const ccTerms = [
      'cc_high_fiber',
      'cc_whole_foods',
      'cc_whole',
      'cc_grains',
      'cc_fruit',
      'cc_vegetable',
      'cc_legume',
      'cc_protein',
      'cc_fish',
      'cc_meat',
      'cc_redmeat',
      'cc_non_meat_animal_protein',
      'cc_plant_protein',
      'cc_nuts_seeds',
      'cc_unhealthy_fat',
      'cc_healthy_fat',
      'cc_processed',
      'cc_ultraprocessed',
      'cc_fried',
      'cc_calcium',
      'cc_complexcarbs',
      'cc_simplecarbs',
      'cc_supplement',
      'cc_sugary_beverage',
      'cc_plant_based',
      'cc_plant_based_whole',
      'cc_beverage',
      'cc_raw',
      'cc_poultry',
      'cc_pork',
      'cc_additives',
      'cc_shellfish',
      'cc_gluten_free',
      'cc_cooked',
      'cc_omega_three',
    ];
    const ccFields: (keyof ReportTableRowFields)[] = ccTerms.map(n => n as keyof ReportTableRowFields);

    const getMacroNutrientColumns = (): RxTableColumn<ReportTableRowFields>[] => {
      if (columnGroup != 'macro') {
        return [];
      }

      return macroFields.map(field => {
        return {
          id: field as string,
          field: field,
          sortable: true,
          header: formatNutrientFieldHeader(field),
          cellRenderer: nutrientCellRenderer,
        };
      });
    };

    const getMicroNutrientColumns = (): RxTableColumn<ReportTableRowFields>[] => {
      if (columnGroup != 'micro') {
        return [];
      }

      return microFields.map(field => {
        return {
          id: field as string,
          field: field,
          sortable: true,
          header: formatNutrientFieldHeader(field),
          cellRenderer: nutrientCellRenderer,
        };
      });
    };

    const getCColumns = (): RxTableColumn<ReportTableRowFields>[] => {
      if (columnGroup != 'cc') {
        return [];
      }

      return ccFields.map(field => {
        return {
          id: field as string,
          field: field,
          sortable: false,
          header: formatCCFieldHeader(field),
          cellRenderer: ccColCellRenderer,
        };
      });
    };

    return [
      {
        cellRenderer: ({ row }) => renderMealItemName(row as MealItemResponse),
        header: 'Name',
        id: 'food_name',
      },
      {
        id: 'sizing',
        header: 'Sizing',
        cellRenderer: ({ row }) => formatMealItemSizing(row as MealItemResponse),
      },
      {
        id: 'weight',
        header: 'Weight',
        cellRenderer: ({ row }) => renderMealItemWeight(row as MealItemResponse),
      },
      ...getMacroNutrientColumns(),
      ...getMicroNutrientColumns(),
      ...getCColumns(),
    ];
  }, [columnGroup]);

  const mealItemAddonColumns: RxTableColumn<{ item: MealItemCustomAddonResponse, parent: MealItemResponse }>[] = [
    {
      cellRenderer: ({ row }) => renderAddonName(row),
      header: 'Name',
      id: 'food_name',
    },
    {
      header: 'Sizing',
      id: 'sizing',
      cellRenderer: ({ row }) => renderAddonSizing(row),
    },
    {
      header: 'Weight',
      id: 'weight',
      cellRenderer: ({ row }) => renderAddonWeight(row),
    },
  ];

  const [selectedColumns, setSelectedColumns] = useState(mealItemColumns);

  const tableColumns = mealItemColumns.filter(column => selectedColumns.map(c => c.id).includes(column.id));
  const addonColumns = mealItemAddonColumns.filter(column => selectedColumns.map(c => c.id).includes(column.id));

  if (query.isLoading) {
    return <div>Loading...</div>;
  }

  if (query.isError) {
    return <div>Error: {'' + query.error}</div>;
  }

  const report = query.data;
  if (!report) {
    return <div>Report not found</div>;
  }

  const tableStickyCellStyle: React.CSSProperties = {
    position: 'sticky',
    left: 0,
    background: 'white',
  };

  return (
    <>
      <PopoverImageViewer />
      <Stack spacing={2}>
        <PatientReportItemHeader report={report!} />
        <MainCard>
          <PatientReportActions report={report} />
        </MainCard>

        <PatientReportErrorFlagsTable report={report} />

        <MainCard>
          <Stack direction="row" spacing={5} sx={{ marginBottom: 5 }}>
            <FormControl fullWidth>
              <InputLabel>Column Group</InputLabel>
              <Select
                value={columnGroup}
                label="Column Groups"
                onChange={(evt: any) => setColumnGroup(evt.target.value)}
              >
                <MenuItem value="macro">Macros</MenuItem>
                <MenuItem value="micro">Micros</MenuItem>
                <MenuItem value="cc">CC</MenuItem>
              </Select>
            </FormControl>
            <FormGroup>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    onChange={() => {
                      if (!disaggregateMealItems) {
                        setDisaggregateMealItems(true);
                        return;
                      }
                      setDisaggregateMealItems(false);
                    }}
                  />
                }
                label="Show addon as meal items"
              />
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    defaultChecked={groupMealItems}
                    onChange={() => {
                      if (!groupMealItems) {
                        setGroupMealItems(true);
                        return;
                      }
                      setGroupMealItems(false);
                    }}
                  />
                }
                label="Group Meal Items by Meal"
              />
            </FormGroup>
          </Stack>
          <RxTableColumnSelect
            columns={mealItemColumns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
          <TableContainer style={{ maxWidth: '100%', marginTop: 20, maxHeight: 700 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow style={{ backgroundColor: 'unset' }}>
                  <HeaderTableCell>Date</HeaderTableCell>
                  <HeaderTableCell>Meal</HeaderTableCell>
                  {tableColumns.map((
                    column,
                    index,
                  ) => {
                    const style = column.id == 'food_name' ? { ...tableStickyCellStyle, zIndex: 10 } : {};
                    return (
                      <HeaderTableCell
                        key={index}
                        style={{
                          ...style,
                          cursor: column.sortable ? 'pointer' : 'default',
                          whiteSpace: 'nowrap',
                        }}
                        onClick={() => handleSortColumn((column.field || '') as any)}
                      >
                        <span>{column.header}</span>
                        {column.sortable && (
                          !sortOptions.direction || sortOptions.field != column.field
                            ? <UnfoldMore />
                            : sortOptions.direction == 'asc'
                            ? <ExpandLess />
                            : sortOptions.direction == 'desc'
                            ? <ExpandMore />
                            : null
                        )}
                      </HeaderTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {mealsGrouped.map((gm, index) => {
                  const dateRowLength = gm.length + gm.meals.length + 1;
                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        style={{
                          backgroundColor: index % 2 != 0 ? theme.palette.grey[50] : theme.palette.secondary.lighter,
                        }}
                      >
                        <TableCell rowSpan={dateRowLength}>
                          <b>{gm.header}</b>
                        </TableCell>
                      </TableRow>
                      {gm.meals.map((meal, index) => {
                        let addonLength: number = 0;
                        meal.meal_items.forEach(item => {
                          addonLength += item.custom_addons?.length || 0;
                        });
                        const mealRowLength = meal.meal_items.length + addonLength + 1;
                        mealRowIndex.current++;
                        return (
                          <React.Fragment key={index}>
                            <TableRow
                              style={{
                                backgroundColor: mealRowIndex.current % 2 != 0
                                  ? theme.palette.grey[50]
                                  : theme.palette.secondary.lighter,
                              }}
                            >
                              <TableCell rowSpan={mealRowLength}>
                                {meal.meal_name.slice(0, 3)}{' '}
                                <span style={{ whiteSpace: 'nowrap' }}>
                                  {meal.id}{' '}
                                  <Link
                                    style={{ cursor: 'pointer' }}
                                    to={`/meal/${meal.id}`}
                                    target="_blank"
                                  >
                                    <FontAwesomeIcon
                                      icon={faExternalLinkAlt}
                                      size="sm"
                                      color="black"
                                    />
                                  </Link>
                                </span>
                                <PatientReportMealTableMealImage meal={meal} />
                              </TableCell>
                            </TableRow>
                            {meal.meal_items.map((item, index) => {
                              return [
                                renderMealItem(
                                  item,
                                  index,
                                  tableColumns,
                                ),
                                ...item.custom_addons?.map(addon =>
                                  renderAddon(
                                    addon,
                                    item,
                                    addonColumns,
                                  )
                                ) || [],
                              ];
                            })}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </MainCard>
      </Stack>
    </>
  );
};
