import _ from 'lodash';

import {
  MealItemCustomAddonResponse,
  type MealItemResponse,
  MealPhotoQueueResponse,
  NutrientEstimatesRequest,
  UsdaNutritionResponse,
} from 'api/generated/MNT';
import { type MealHistoryItem } from 'apiClients/mealHistory';
import { type MealItem } from 'apiClients/mpq';
import { type SearchItem } from 'apiClients/search';
import { nbsp } from 'utils';

import { sandwichReplacementServingUnits, SERVING_TYPE_ITEMS } from '../constants';
import { coalesceNaN } from './numerical';

export const isValidMealItem = (item: MealItem): boolean => {
  // check for sandwich replacement items uses hard coded values and can be adapted to additional replacement items if needed
  if (item.food_name == 'sandwich' && item.food_replacement_name != null) {
    return sandwichReplacementServingUnits.find(r => (
      r.serving_units.amount == item.serving_unit_amount && r.food_replacement_name == item.food_replacement_name
    ))?.serving_units != undefined;
  }

  // there are cases we would like to handle where serving labels don't match (ie label not in search item) and also where serving units don't match (variation in decimal values), check simplified to existence of these attributes
  return !!(item.serving_unit_label) && !!(item.serving_unit_amount);
};

const baseMealItem = {
  addons: [],
  custom_addons: [],
  custom_item: false,
  custom_usda_id: null,
  custom_item_source: null,
  custom_item_source_id: null,
  food_replacement_name: null,
  note: null,
  nutrition_source: null,
};

export const recentItemToMealItem = (
  queueItem: MealPhotoQueueResponse,
  recentItem: MealItemResponse,
): MealItem => {
  return {
    ...baseMealItem,
    note: null,
    addons: [],
    custom_addons: recentItem.custom_addons,
    custom_item: recentItem.custom_item,
    custom_item_source: recentItem.custom_item_source ?? null,
    custom_item_source_id: recentItem.custom_item_source_id ?? null,
    food_name: (recentItem.food_name || 'Missing'),
    food_name_alias: recentItem.food_name_alias || null,
    food_replacement_name: recentItem.food_replacement_name,
    meal_photo_id: queueItem.meal_photo_id,
    preparation_method: recentItem.preparation_method,
    serving_type_label: (recentItem.serving_type_label == 'null' || !recentItem.serving_type_label
      ? null
      : recentItem.serving_type_label),
    serving_type_multiplier:
      (recentItem.serving_type_multiplier == null ? null : Number(recentItem.serving_type_multiplier)),
    serving_unit_amount: (recentItem.serving_unit_amount ?? 0),
    serving_unit_label: (recentItem.serving_unit_label ?? ''),
    servings: (recentItem.servings ?? 0),
    food_ontology: recentItem.food_ontology ?? null,
  };
};

export const mealHistoryItemToMealItem = (historyItem: MealItemResponse, mealPhotoId?: number | null): MealItem => {
  const mealItem: MealItem = {
    ...baseMealItem,
    addons: [],
    custom_addons: historyItem.custom_addons || [],
    custom_item_source: historyItem.custom_item_source ?? null,
    custom_item_source_id: historyItem.custom_item_source_id ?? null,
    food_name: historyItem.food_name,
    food_name_alias: historyItem.food_name_alias || null,
    food_replacement_name: historyItem.food_replacement_name,
    meal_photo_id: mealPhotoId,
    note: null,
    preparation_method: historyItem.preparation_method,
    serving_type_label: (historyItem.serving_type_label == null || historyItem.serving_type_label == 'null'
        || historyItem.serving_type_label == ''
      ? null
      : historyItem.serving_type_label),
    serving_type_multiplier:
      (historyItem.serving_type_multiplier == null ? null : Number(historyItem.serving_type_multiplier)),
    serving_unit_amount: (historyItem.serving_unit_amount == null ? 0 : historyItem.serving_unit_amount),
    serving_unit_label: (historyItem.serving_unit_label == null ? 'Missing' : historyItem.serving_unit_label),
    servings: (historyItem.servings == null ? 0 : historyItem.servings),
  };
  return mealItem;
};

export const searchItemToMealItem = (searchItem: SearchItem, mealPhotoId?: number): MealItem => ({
  ...baseMealItem,
  custom_tip: searchItem.custom_tip,
  food_name: searchItem.name,
  food_name_alias: null,
  meal_photo_id: mealPhotoId,
  serving_type_label: (typeof searchItem.serving_types !== 'undefined' && SERVING_TYPE_ITEMS.includes(searchItem.name))
    ? searchItem.serving_types[0].label
    : null,
  serving_type_multiplier: (typeof searchItem.serving_types !== 'undefined' && searchItem?.serving_types !== null
      && SERVING_TYPE_ITEMS.includes(searchItem.name))
    ? searchItem.serving_types[0].multiplier
    : null,
  // note: default serving unit_label is the first item in the array
  // assumption: meal items added from search do not require validation
  serving_unit_amount: (typeof searchItem.serving_units !== 'undefined' && searchItem?.serving_types !== null)
    ? searchItem.serving_units[0].amount
    : 1,
  serving_unit_label: (typeof searchItem.serving_units !== 'undefined' && searchItem?.serving_types !== null)
    ? searchItem.serving_units[0].label
    : 'gram',
  servings: (typeof searchItem.serving_units !== 'undefined' && searchItem?.serving_types !== null)
    ? (typeof searchItem.serving_units[0].default_label_qty !== 'undefined'
      ? searchItem.serving_units[0].default_label_qty
      : 1)
    : 1,
  food_ontology: [searchItem.level1, searchItem.level2, searchItem.level3, searchItem.level4].filter(
    Boolean,
  ) as string[],
});

type _NutrientDef = {
  label: string,
  nutrient: keyof UsdaNutritionResponse,
  suffix: string,
  decimals: number,
};

export type NutrientDef = _NutrientDef & {
  format: (value: number) => string,
  formatNumber: (value: number) => string,
};

const nutrientDefs: Partial<Record<keyof UsdaNutritionResponse, _NutrientDef>> = {
  'carbohydrate_g': {
    label: 'Carbs',
    nutrient: 'carbohydrate_g',
    suffix: 'g',
    decimals: 1,
  },
  'fiber_g': {
    label: 'Fibre',
    nutrient: 'fiber_g',
    suffix: 'g',
    decimals: 1,
  },
  'polyols_g': {
    label: 'Polyols',
    nutrient: 'polyols_g',
    suffix: 'g',
    decimals: 1,
  },
  'netcarb_g': {
    label: nbsp('Net carbs'),
    nutrient: 'netcarb_g',
    suffix: 'g',
    decimals: 1,
  },
  'protein_g': {
    label: 'Protein',
    nutrient: 'protein_g',
    suffix: 'g',
    decimals: 1,
  },
  'fat_g': {
    label: 'Fat',
    nutrient: 'fat_g',
    suffix: 'g',
    decimals: 1,
  },
  'energy_kcal': {
    label: 'Energy',
    nutrient: 'energy_kcal',
    suffix: 'kcal',
    decimals: 0,
  },
  'alcohol_g': {
    label: 'Alcohol',
    nutrient: 'alcohol_g',
    suffix: 'g',
    decimals: 1,
  },
};

export const nutrientGetDef = (nutrient: keyof UsdaNutritionResponse): NutrientDef => {
  const _res: _NutrientDef = nutrientDefs[nutrient] ?? {
    label: nutrient,
    nutrient,
    suffix: '?',
    decimals: 0,
  };

  const res = _res as NutrientDef;
  if (!res.format) {
    res.formatNumber = (value: number) => {
      if (typeof value !== 'number' || Number.isNaN(value)) {
        return '-';
      }
      return value.toFixed(res.decimals);
    };
    res.format = (value: number) => {
      if (typeof value !== 'number' || Number.isNaN(value)) {
        return '-';
      }
      return `${res.formatNumber(value)}${res.suffix}`;
    };
  }

  return res;
};

export type NutrientValue = {
  nutrient: keyof UsdaNutritionResponse,
  value: number, // Note: may be NaN
  valueStr: string,
  def: NutrientDef,
  warnings: string[],
};

export const NUTRIENT_VALUE_LOADING: NutrientValue = {
  nutrient: 'loading' as any,
  value: NaN,
  valueStr: '…',
  def: nutrientGetDef('loading' as any),
  warnings: [],
};

/**
 * Gets the value of a nutrient for a meal item.
 *
 * Considers serving unit amount, servings, percent eaten, and custom nutrient estimates.
 *
 * Note that the value may be NaN if the nutrient is not defined for the item.
 *
 * See the implementation of `<MealItemNutrientValue />` for an example of how
 * to use the result.
 *
 * Example::
 *
 *   const ShowNutrientValue = (props: {
 *     item: MealItem | MealItemCustomAddonResponse,
 *     nutrient: keyof UsdaNutritionResponse,
 *   }) => {
 *     const details = useFoodDetails(props.item.food_name);
 *     const value = details.isLoading ? LOADING_NUTRIENT_VALUE : mealItemGetNutrientValue({
 *       item: props.item,
 *       foodNutrients: details.usda_nutrition,
 *       nutrient: props.nutrient,
 *     });
 *
 *     console.log(`The value of ${value.def.label}: is ${value.valueStr} (warnings: ${value.warnings.join(', ')})`);
 *
 *     return <MealItemNutrientValue value={value} />;
 *   };
 */
export const mealItemGetNutrientValue = (args: {
  item: MealItem | MealItemCustomAddonResponse | MealItemResponse,
  foodNutrients: UsdaNutritionResponse | null,
  nutrient: keyof UsdaNutritionResponse,
  ignoreCustomEstimates?: boolean,
}): NutrientValue => {
  const { item, nutrient, foodNutrients } = args;
  const def = nutrientGetDef(nutrient);
  const servingUnitQty = item.servings ?? 1;
  const servingUnitAmount = item.serving_unit_amount ?? 1;
  const pctEaten = (item as MealItem).percent_eaten ?? 1;

  // nutrient_overrides are per 100g for consistency with calculations
  const nutrientOverrides = _.sortBy(Object.entries((item as MealItem).nutrient_overrides ?? {}), entry => entry[0]);

  for (const [_, override] of nutrientOverrides) {
    const overrideVal = (override.nutrients as any)?.[nutrient];
    if (typeof overrideVal != 'number') {
      continue;
    }
    const scaledVal = overrideVal * servingUnitQty * (servingUnitAmount / 100) * pctEaten;
    return {
      nutrient,
      value: scaledVal,
      valueStr: def.format(scaledVal),
      def: def,
      warnings: override.source_name == 'patient' ? [`'${nutrient}' has been overridden by patient`] : [],
    };
  }

  // custom_nutrient_estimates to be deprecated
  // when it is migrated over to nutrient_overrides
  const shouldIgnoreCustomEstimates = args.ignoreCustomEstimates
    || !(item as MealItem).custom_item;

  const estimates = shouldIgnoreCustomEstimates
    ? {}
    : (item as MealItem).custom_nutrient_estimates || {};
  const estimate = (estimates as any)[nutrient];
  if (typeof estimate === 'number') {
    return {
      nutrient,
      // custom_nutrient_estimates are expected to match only
      // the exact meal item amount and is not scaled to per 100g
      value: estimate * pctEaten,
      valueStr: def.format(estimate * pctEaten),
      def,
      warnings: [`'${nutrient}' has been estimated`],
    };
  }

  if (nutrient == 'netcarb_g') {
    const carb = mealItemGetNutrientValue({
      ...args,
      nutrient: 'carbohydrate_g',
    });
    const fiber = mealItemGetNutrientValue({
      ...args,
      nutrient: 'fiber_g',
    });
    const polyols = mealItemGetNutrientValue({
      ...args,
      nutrient: 'polyols_g',
    });
    const value = carb.value - fiber.value - coalesceNaN(polyols.value, 0);
    return {
      nutrient,
      value: value,
      valueStr: def.format(value),
      def,
      warnings: [
        ...carb.warnings,
        ...fiber.warnings,
        ...polyols.warnings,
      ],
    };
  }

  if (!foodNutrients) {
    const itemVal = (item as any)?.[nutrient];
    if (typeof itemVal === 'number') {
      return {
        nutrient,
        value: itemVal,
        valueStr: def.format(itemVal),
        def,
        warnings: [`'${nutrient}' from custom item`],
      };
    }

    return {
      nutrient,
      value: NaN,
      valueStr: def.format(NaN),
      def: def,
      warnings: [], // Note: this is noisy, hide it for now [`'${nutrient}' not defined`],
    };
  }

  // Note: the `foodNutrients` object is defined, assume that any missing nutrients
  // are `0`, because this is how the backend works.
  const baseVal: number | null | undefined = foodNutrients[nutrient] || 0;
  const scaledVal = baseVal * servingUnitQty * (servingUnitAmount / 100) * pctEaten;

  return {
    nutrient,
    value: scaledVal,
    valueStr: def.format(scaledVal),
    def: def,
    warnings: [],
  };
};
